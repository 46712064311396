import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Alert, Modal } from 'react-bootstrap';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { validatePassword } from '../../util/PasswordValidation';
import { validateBirthday } from '../../util/BirthdayValidation';
import { validateEmail } from '../../util/EmailValidation';
import TermsAndConditions from '../../components/TermsAndCondition';
import { useExecuteRecaptcha } from '../../util/ReCaptcha';


function RegisterAccount() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();
  const { execute } = useExecuteRecaptcha();

  const handleRegisterAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    // if username is shorter than 4 characters or longer than 12 characters in length, set error
    if (username.length < 4 || username.length > 12) {
      setError('Username must be between 4 and 12 characters');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      setLoading(false);
      return;
    }

    const birthdayError = validateBirthday(birthday);
    if (birthdayError) {
      setError(birthdayError);
      setLoading(false);
      return;
    }
    
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      setLoading(false);
      return;
    }
    try 
    {
      const token = await execute('register');

      const response = await fetch(`${config.httpsProtocol}${config.apiBaseUrl}${config.endpoints.register}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name: username, 
          password: password, 
          confirmPassword: confirmPassword, 
          birthday: birthday, 
          email: email,
          reCaptchaToken: token 
        }),
      });

      const jsonData = await response.json();
      const message = jsonData.result.message;

      if (response.ok) 
      {                
        if(jsonData.result.refreshPage)
        {
          var successMessage = message + " Redirecting to login page...";
          setSuccess(successMessage);
          setSuccessMessage(true);

          setUsername('');
          setPassword('');
          setConfirmPassword('');
          setBirthday('');
          setEmail('');

          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
        else
        {
          setError(message || 'Failed to create account');
        }
      } 
      else 
      {
        setError(jsonData.message || 'Failed to create account');
      }
    } 
    catch (error) 
    {
      setError('Network error');
    } 
    finally 
    {
      setLoading(false);
    }
  };

  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleRegisterAccount}>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your username" 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBirthday">
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            {loading ? (
              <Spinner animation="border" role="status" className="d-block mx-auto mt-3">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <>
                <p className="mt-3">
                  By registering, you agree to the <Button variant="link" onClick={handleShowTerms} style={{ padding: 0 }}>terms and conditions</Button>.
                </p>
                <Button variant="primary" type="submit">
                  Register Account
                </Button>
              </>
            )}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">{success}</Alert>}
            {/*successMessage && (
              <Alert variant="info" className="mt-3">
                All unverified accounts will be deleted after 1 week.
              </Alert>
            */}
          </Form>
        </Col>
      </Row>
      <Modal show={showTerms} onHide={handleCloseTerms}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTerms}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default RegisterAccount;
